.css-vi2f54-control:hover {
	border-color: #898d91 !important;
}

.user-popover {
	margin-bottom: 2px;
}

.text-ellipsis {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.overlay-popover {
	background-color: #000;
	color: #fff;
	border-radius: 4px;
	margin-top: 8px;

	&_text {
		margin: 4px 8px;
	}

	.popover-arrow::after,
	.popover-arrow::before {
		border-bottom-color: #000;
	}
}

.value-container {
	width: 175px;
	margin-bottom: 2px;
	display: flex;
	align-items: center;

	&_label {
		max-width: 68px;
		color: #212529;
	}

	&_value {
		color: #6b7075;
	}

	&_hyphen {
		margin-left: 3px;
		margin-right: 3px;
	}
}

.option-container {
	display: flex;
	align-items: center;

	&_input {
		margin-right: 8px;
		width: 15px;
		height: 15px;
		cursor: pointer;
	}

	&_label {
		font-size: 14px;
		display: flex;
		align-items: center;
		width: 175px;
		cursor: pointer;

		&--name {
			max-width: 68px;
			color: #212529;
		}

		&--value {
			color: #6b7075;
		}

		&--hyphen {
			margin-left: 3px;
			margin-right: 3px;
			color: #212529;
		}
	}
}

.disabled-dropdown {
	pointer-events: none;
	opacity: 0.65;
}

.cursor-nodrop {
	cursor: no-drop;
}
#userOfficeLocation [id^="react-select-"][id*="-option-0"] {
	border-width: 1px;
	border-style:solid;
	color: #898D91;
	width: 205px;
	border-right: none;
	border-top: none;
	border-left: none;
  }